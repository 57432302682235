<template>
  <div>
    <div v-if="authorizer.appId" class="auth-succ">
      <div class="user-info">
        <img :src="authorizer.avatar" class="avatar" />
        <div class="nickname">{{ authorizer.nickname }}</div>
      </div>
      <div class="succ-msg">
        <h2>授权成功!</h2>
        <div v-if="authorizer.serviceTypeInfo === 2" class="tips">请联系管理人员开通账号及支付功能。</div>
        <div v-else class="tips danger">注意：当前公众号类型并非“服务号”，可能无法开通支付功能！</div>
        <div class="tips more">当前页面可以关闭了。</div>
      </div>
    </div>
    <div v-else class="before-auth">
      <h2>授权说明</h2>
      <ul class="info">
        <li>请公众号管理员进行授权操作</li>
        <li>请确保公众号类型为“服务号”</li>
      </ul>
      <van-button type="primary" block @click="doAuth">公众号授权</van-button>
    </div>
  </div>
</template>
<script>
import wxComAuthApi from '@/api/wxcom-auth'
import request from '@/utils/request'
import store from '@/store'

export default {
  data() {
    return {
      comAppId: process.env.VUE_APP_COM_APPID, // 平台appId
      preAuthCode: '',
      redirectUrl: '',
      authType: '1',
      msg: '',
      a: {
        appId: '123',
        avatar: 'http://wx.qlogo.cn/mmopen/wNebOgngsMLXgyicvwSyEAbrNjFfnmv3esDsfo5oNicAOW50ibeFTESUjNicwaPWh0wbmNkVBByic6mQa9KjUCZ82G1Cjgs5mMn6U/0',
        nickName: '办学有术',
        serviceTypeInfo: 1
      }
    }
  },
  computed: {
    authorizer() {
      let authorizer = {}
      if (sessionStorage.getItem('authorizer')) {
        authorizer = JSON.parse(sessionStorage.getItem('authorizer'))
      }
      return authorizer
    }
  },
  created() {},
  mounted() {
    this.url = this.$route.fullPath
    this.initData()
  },
  methods: {
    doAuth() {
      // 点击授权按钮时，跳转到“微信”的授权页面
      // 指定授权成功后的回调页面，还是本页面，但是会带上微信传入的：auth_code,这时，会被本页面的beforeRouteEnter()处理
      let redirectUrl = encodeURIComponent(this.redirectUrl)
      window.location.href = `https://mp.weixin.qq.com/safe/bindcomponent?action=bindcomponent&no_scan=1&component_appid=${this.comAppId}&pre_auth_code=${this.preAuthCode}&redirect_uri=${redirectUrl}&auth_type=${this.authType}#wechat_redirect`
    },
    initData() {
      // 首先获取预授权码
      wxComAuthApi.getPreAuthCode().then(res => {
        if (res.succ) {
          this.preAuthCode = res.data.preAuthCode
          this.redirectUrl = window.location.href
        }
      })
    }
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.meta.title) {
      document.title = to.meta.title
    } else {
      document.title = '易办学'
    }
    let fullPath = to.fullPath
    let authCode = ''
    if (fullPath.includes('auth_code')) {
      // 判断是否为微信回调请求，是否带有授权码
      for (let i in to.query) {
        if (i === 'auth_code') {
          authCode = to.query[i]
          break
        }
      }
    }
    if (authCode !== '' && authCode.length > 0) {
      // 有授权码，请求后台，由后台请求微信服务器调取授权方信息，并返回数据
      request.get('/api/wxcom/authCode', { params: { authCode } }).then(res => {
        if (res.succ) {
          // 将用户信息保存到sessionStorage中，用于页面显示
          sessionStorage.setItem('authorizer', JSON.stringify(res.data.item))
          // store.dispatch('saveAuthorizer', res.data.item)
          next()
        }
      })
    } else {
      next()
    }
  }
}
</script>
<style lang="less" scoped>
h2 {
  font-size: 0.5rem;
  text-align: center;
  margin: 0.4rem;
}
.auth-succ {
  padding: 0.6rem 1.2rem;
  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .avatar {
      width: 2rem;
      flex: 1;
      border-radius: 50%;
    }
    .nickname {
      flex: 3;
      padding-left: 0.6rem;
      font-size: 0.6rem;
      font-weight: bold;
    }
  }
  .succ-msg {
    padding: 0.5rem 0;
    text-align: center;
    .tips {
      font-size: 0.4rem;
      min-height: 1rem;
      line-height: 1rem;
    }
    .danger {
      color: red;
      font-weight: bold;
    }
    .more {
      color: #999;
    }
  }
}
.before-auth {
  padding: 0.6rem 1.2rem;
  .info {
    padding: 0.3rem;
    font-size: 0.35rem;
    li {
      padding: 0.2rem 0.1rem;
    }
  }
}
</style>
